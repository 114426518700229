import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import AboveFooter from "../components/AboveFooter"

export const WebApplicationPageTemplate = ({
  seo,
  hero,
  contactus,
  sstsection,
  waosection,
  fixedimagesection
}) => (
    <div>
      <SEO title={seo.title} description={seo.description} article_section={seo.article_section} blogservice={true} />
      <HeroOther data={hero} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <SubTitle title={contactus.title} />
              <SubTitle title={contactus.secondtitle} />
            </div>
            <div className="column is-4">
              <a href={contactus.buttonlink} >
                <button style={{ fontSize: 20, width: "100%", background: "#53c5d1" }}>{contactus.buttontxt}</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${sstsection.sectionbg}` }}>
        <div className="container">
          <div style={{ margin: "2rem 0" }}>
            <Title title={sstsection.title} color={sstsection.textcolor} />
            <Title title={sstsection.secondtitle} color={sstsection.textcolor} />
            <SubTitle title={sstsection.subheader1} color={sstsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={sstsection.descriptions1} color={sstsection.textcolor} margin="1rem 0 0" />
            <SubTitle title={sstsection.subheader2} color={sstsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={sstsection.descriptions2} color={sstsection.textcolor} margin="1rem 0 0" />
          </div>
          <div className="columns">
            <div className="column is-4">
              <div style={{ maxWidth: 300, height: 100 }}>
                <PreviewCompatibleImage imageInfo={sstsection.image} />
              </div>
            </div>
            <div className="column is-8">
              <Descriptions descriptions={sstsection.sidedescriptions} color={sstsection.textcolor} />
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${waosection.sectionbg}` }}>
        <div className="container">
          <div className="columns" style={{ marginTop: "4rem" }}>
            <div className="column is-8">
              <Title title={waosection.title} color={waosection.textcolor} />
              <Title title={waosection.secondtitle} color={waosection.textcolor} />
              <SubTitle title={waosection.subheader1} color={waosection.textcolor} margin="1.5rem 0 0" />
            </div>
            <div className="column is-4">
              <div style={{ maxWidth: 400, height: 100 }}>
                <PreviewCompatibleImage imageInfo={waosection.image} />
              </div>
            </div>
          </div>
          <div>
            <Descriptions descriptions={waosection.descriptions1} color={waosection.textcolor} />
            <SubTitle title={waosection.subheader2} color={waosection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={waosection.descriptions2} color={waosection.textcolor} margin="1rem 0" />
          </div>
        </div>
      </section>
      <AboveFooter data={fixedimagesection} height="300px" />
    </div>
  )

WebApplicationPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  sstsection: PropTypes.object,
  waosection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const WebApplicationPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <WebApplicationPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        sstsection={frontmatter.sstsection}
        waosection={frontmatter.waosection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

WebApplicationPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default WebApplicationPage

export const pageQuery = graphql`
  query WebApplicationPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "web-application" } }) {
      frontmatter {
        seo {
          title
          description
          article_section
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        sstsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader1
          descriptions1
          subheader2
          descriptions2
          sidedescriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        waosection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader1
          descriptions1
          subheader2
          descriptions2
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
